import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ng2-tooltip-directive';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { SableRoutingModule } from './sable-routing.module';
import { SableComponent } from './sable.component';
import { GestorComponent } from './modules/gestor_descarga/gestor.component';
import { CrearComponent } from './modules/crear_descarga/crear.component';
import { CreateLabelDialogComponent } from './modules/create-label-dialog/create-label-dialog.component';
import { AdminServersComponent } from './modules/admin-servers/admin-servers.component';
import { ComandosPageComponent } from './modules/comandos-page/comandos-page.component';
import { ComandosCreateComponent } from './modules/comandos-create/comandos-create.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RecordsComponent } from './modules/records/records.component';
import { RecordDialogComponent } from './modules/records/dialogs/record-dialog/record-dialog.component';
import { VideoRecordsComponent } from './modules/records/video-records/video-records.component';

@NgModule({
  declarations: [
    SableComponent,
    GestorComponent,
    CrearComponent,
    CreateLabelDialogComponent,
    AdminServersComponent,
    ComandosPageComponent,
    ComandosCreateComponent,
    RecordsComponent,
    RecordDialogComponent,
    VideoRecordsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SableRoutingModule,
    TooltipModule,
    MaterialModule,
    SharedModule
  ]
})

export class SableModule { }