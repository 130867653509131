import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { MeetingsService } from '../../services/meetings.service';

@Component({
  selector: 'app-history-details',
  templateUrl: './history-details.component.html',
  styleUrls: ['./history-details.component.sass']
})
export class HistoryDetailsComponent implements OnInit {

  roomInfo: any;
  constructor(
    private videcallservice: MeetingsService,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
  ) {}

  ngOnInit(): void {
    this.getInfoRoom(this.dialog_data.id)
  }

  /**
   * Metodo trae toda la informacion para mostrar
   * @author Yeison David Sepulveda Ospina
   * @createdate    06-12-2024
   * @returns void {void}
   */
  getInfoRoom(id) {
    this.videcallservice.getVideoCallById(id).subscribe(
      (res) => {
        const startDate = res.start_date ? moment(res.start_date) : null;
        const endDate = res.end_date ? moment(res.end_date) : null;
        const duration = startDate && endDate ? moment.utc(endDate.diff(startDate)).format('HH:mm:ss') : "No disponible";

        const participantsNames = [
          res.client_name,
          res.advisers.name_adviser
        ].join(', ');

        this.roomInfo = {
          client_nic: res.client_nic,
          client_name: res.client_name,
          scheduled_date: moment(res.scheduled_date).format('DD/MM/YYYY'),
          scheduled_time: moment(res.scheduled_date).format('HH:mm'),
          state_name: res.state.name,
          duration: duration,
          participantsNames: participantsNames,
          adviser_id: res.advisers.id,
          adviser_name: res.advisers.name_adviser,
          attended_by_agent_id: res.advisers.id,
          attended_by_agent_name: res.advisers.name_adviser,
          files: res.linksFiles.map(file => ({
            name: file.name,
            url: file.fullPath
          })),
          linkRecording: res.linkRecording
        };
      },
      (error) => {
        console.error('Error al obtener la información', error);
      }
    );
  }



}
