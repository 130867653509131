<div class="main">
  <div class="pn-lateral-56-24 mtn-64">
    <div class="col-12">
      <h1>Administrador de videollamadas</h1>
    </div>
    <p>
      Aquí podrás administrar algunas configuraciones de las videollamadas
      <br />
      como los rangos de atención y fondos virtuales.
    </p>

    <table class="mt-3" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            [matTooltip]="'Editar'"
            (click)="openRescheduleForm(element)"
          >
            <i class="fi-rr-pencil"></i>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="campaign_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Campaña</th>
        <td mat-cell *matCellDef="let element">{{ element.campaign_name }}</td>
      </ng-container>

      <ng-container matColumnDef="interval_rooms">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Administrador de videollamadas
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.interval_rooms }} Minuto{{
            element.interval_rooms > 1 ? "s" : ""
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="background_url">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          ¿Tiene fondo asignado?
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.background_url ? "Sí" : "No" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" style="background: white" *matNoDataRow>
        <td class="mat-cell" colspan="5">
          No se encontraron campañas que coincidan con los criterios de búsqueda
        </td>
      </tr>
    </table>
    <mat-paginator
      (page)="pageEvent($event)"
      [length]="length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
  </div>
</div>
