import { Component, OnInit } from '@angular/core';
import { ConfigTable, Paginator } from 'src/app/shared/basic-crud-page/type';
import { MatDialog } from '@angular/material/dialog';
import { CrudService } from 'src/app/shared/basic-crud-page-service/crud.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { RecordDialogComponent } from '../dialogs/record-dialog/record-dialog.component';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';

const { MEETINGS_URL } = environment;

@Component({
  selector: 'app-video-records',
  templateUrl: './video-records.component.html',
  styleUrls: ['./video-records.component.sass']
})
export class VideoRecordsComponent implements OnInit {
  // videoRecords: any[] = [{ id_client: 1015438482, date: '13/12/2024 10:30', rrhhId_agent: 1205, recording: 'file:///C:/Users/Fabian/Pictures/prueba.mp4' }];
  videoRecords: any[] = [];
  configColumnsTable: ConfigTable[] = [
    { name: '', key: 'actions', type: 'events' },
    { name: 'NIT del cliente', key: 'id_client' },
    { name: 'Nombre del cliente', key: 'client_name' },
    { name: 'Fecha - Hora', key: 'date' },
    { name: 'Agente ID', key: 'rrhhId_agent' },
    { name: 'Estado', key: 'state_recor' },
  ];
  configPaginator: Paginator = { length: 0, pageIndex: 0, pageSize: 5 };
  filter: string = null;
  filter2: string = null;
  filter3: string = null;
  filterInitDate: string = null;
  filterEndDate: string = null;
  max_date: Date = new Date();

  textFilterModel: string = null;
  textFilterModel2: string = null;
  textFilterModel3: string = null;

  dateFilter: Date = null;
  dateFilterStart: Date = null;
  dateFilterEnd: Date = null;

  constructor(
    private matDialog: MatDialog,
    private crudService: CrudService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    this.getVideoRecords();
  }

  /**
   * @author Fabian Duran
   * @createdate 2023-07-15
   * Metodo que redirecciona a la pagina anterior visitada.
   */
  backToPreviousPage(): void {
    this.location.back();
  }

  /**
    * @author Fabian Duran
    * @createdate 2024-12-04
    * Metodo que trae las carpetas registradas en el sistema.
  */
  getVideoRecords(): void {
    const filters = {
      perPage: this.configPaginator.pageSize,
      page: this.configPaginator.pageIndex + 1,
      id_cliente: this.filter,
      client_name: this.filter2,
      agent_name: this.filter3,
      startDate: this.filterInitDate,
      endDate: this.filterEndDate,
    };
    this.crudService.postData({ url: `${MEETINGS_URL}recordings/list-paginated`, formData: filters }).subscribe(res => {
      this.videoRecords = res.data;
      this.configPaginator.length = res.total
    });
  }
  /**
   * @author Fabian Duran
   * @createdate 2024-12-04
   * Metodo que setea el filtro emitido por el componente hijo.
   * @param $event Evento emitido por el componente hijo.
   */
  onChangeFilter($event: string, filter_index: number) {
    switch (filter_index) {
      case 1:
        this.filter = $event;
        break;
      case 2:
        this.filter2 = $event;
        break;
      case 3:
        this.filter3 = $event;
        break;
      case 6:
    }
    this.configPaginator.pageIndex = 0;
    this.getVideoRecords();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-12-04
    * Metodo que permite ejecutar la modal para crear o actualizar una carpeta.
    * @param folder Bandeja seleccionada de la tabla.
  */
  onClickShowVideoRecord($event: any): void {
    if ($event.element.recording) {
      this.matDialog
        .open(RecordDialogComponent, {
          width: '50%',
          autoFocus: false,
          data: {
            showVideoRecord: true,
            urlVideo: $event.element.recording,
          },
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) this.getVideoRecords();
        });
    } else {
      Swal.fire({
        title: '¡Atención!',
        text: 'La grabación no esta disponible.',
        icon: 'info',
        confirmButtonText: 'Aceptar',
      });
    }
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-12-04
    * Metodo que permite actualizar la configuracion de la paginacion.
    * @param $event Evento emitido por el componente hijo.
  */
  onChangePage($event: Paginator): void {
    this.configPaginator = $event;
    this.getVideoRecords();
  }
  /**
    * @author Fabian Duran
    * @createdate 2024-12-04
    * Metodo que trae el rango de fechas emitido por el componente hijo.
    * @param $event Evento emitido por el componente hijo.
  */
  onChangeFilterRangeDate($event: { start: Date, end: Date }): void {
    if ($event.start && $event.end) {
      this.filterInitDate = moment($event.start).format('YYYY-MM-DD');
      this.filterEndDate = moment($event.end).format('YYYY-MM-DD');
      this.configPaginator.pageIndex = 0;
      this.getVideoRecords();
    }
  }
  /**
   * @author Juan Carlos Alonso
   * @createdate 2025-01-09
   * Método que resetea el texto que contiene los filtros y trae la información nuevamente
   */
  onClickCelarFiltersButton() {
    this.filter = null;
    this.filter2 = null;
    this.filter3 = null;
    this.getVideoRecords();
  }
  /**
   * @author Fabian Duran
   * @createdate 2024-10-31
   * Metodo que limpia los filtros.
   */
  onClickClearFilters(): void {
    this.textFilterModel = null;
    this.textFilterModel2 = null;
    this.textFilterModel3 = null;
    this.dateFilter = null;
    this.dateFilterStart = null;
    this.dateFilterEnd = null;
  }
}
