import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { MeetingsService } from '../../services/meetings.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { HistoryDetailsComponent } from '../../components/history-details/history-details.component';
import * as moment from 'moment';

@Component({
  selector: 'app-meeting-history',
  templateUrl: './meeting-history.component.html',
  styleUrls: ['./meeting-history.component.sass']
})
export class MeetingHistoryComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort
  dataSource = new MatTableDataSource<any>(); 
  configPaginator: any = { length: 0, pageSize: 5, pageIndex: 1 };
  pagesizeOptions: number[] = [5, 10, 20, 50, 100]
  keysColumnsTable: string[] = [];
  displayedColumns: string[] = ['actions','scheduled_date', 'agent_name', 'client_name','client_nic', 'state'];
  formFilters: FormGroup;
  state_list: any[] = [];

  ngOnInit(): void {
    this.keysColumnsTable = this.configColumnsTable.map((item: any) => item.key);
    this.initFormFilters();
    this.getStates();
    this.search();
  }

  constructor(
    private dialog:MatDialog,
    private videcallservice:MeetingsService,
    private datePipe: DatePipe,
    private alertService: AlertsService,
  ) { }


  /**
   * Metodo donde se establecen las validaciones del formulario
   * @author Yeison David Sepulveda Ospina
   * @createdate    06-12-2024
   * @returns void {void} 
   */
  initFormFilters(): void {
    this.formFilters = new FormGroup({
      dateStart: new FormControl(''),
      dateEnd: new FormControl(''),
      state: new FormControl(''),
      name: new FormControl(''),
    });
  }

  /**
   * Método que pagina la informacion de la tabla.
   * @author Yeison Sepulveda
   * @createdate 06-12-2024
   * @param $event Evento emitido por el paginador.
  */
  changePage($event: PageEvent): void {
    this.configPaginator.pageSize  = $event.pageSize;
    this.configPaginator.pageIndex  = $event.pageIndex + 1;
    this.search();
  }

  /**
   * Configuración para visualizar la informacion en la tabla
   * @author Yeison Sepulveda
   * @createdate 06-12-2024
  */

  configColumnsTable: any[] = [
    { key: 'actions', name: 'Acciones' },
    { key: 'scheduled_date', name: 'Fecha programada' },
    { key: 'agent_name', name: 'Nombre del agente' },
    { key: 'client_name', name: 'Nombre del cliente' },
    { key: 'client_nic', name: 'NIC del cliente' },
    { key: 'state', name: 'Estado' },
  ]; 

  /**
   * Metodo validar rango de fecha
   * @author Yeison David Sepulveda Ospina
   * @createdate 06-12-2024
   * @returns void {void} 
   */
  myDateFilter = (d: Date | null): boolean => {
    const today = new Date(); 
    const dayOfWeek = today.getDay();
    const daysToNextSunday = dayOfWeek === 0 ? 7 : 7 - dayOfWeek; 
    const nextSunday = new Date(today);
    nextSunday.setDate(today.getDate() + daysToNextSunday); 
    return d ? d <= nextSunday : true;
  };
  
  /**
   * Método para el consumo y busqueda por los selectores
   * @author Yeison Sepulveda
   * @createdate 06-12-2024
  */
  search(): void {
    const filters = {
      dateStart: this.formFilters.get('dateStart').value,
      dateEnd: this.formFilters.get('dateEnd').value,
      state: this.formFilters.get('state').value,
      name: this.formFilters.get('name').value,
    };

    this.videcallservice.getRoomsHistoryPaginate(filters, this.configPaginator).subscribe(
      (resp) => {
        const data = resp.data.map((item: any) => {
          return {
            id: item.id,
            scheduled_date: this.datePipe.transform(item.scheduled_date, 'dd/MM/yyyy hh:mm a'),
            agent_name: item.advisers?.name_adviser, 
            client_name: item.client_name,
            client_nic: item.client_nic,
            state: item.state?.name, 
          };
        });

        this.dataSource.data = data;
        this.dataSource.sort = this.sort;
        this.configPaginator.length = resp.total;
      },
      (error) => {
        console.error('Error en la solicitud:', error.error);
      }
    );
  }

  /**
   * Metodo botón de busqueda
   * @author Yeison David Sepulveda Ospina
   * @createdate 06-12-2024
   * @returns void {void}
   */
  searchSelection() {
    this.configPaginator.pageIndex = 0;
    this.dataSource.data = [];
    this.paginator.firstPage();
    this.search();
  }

  /**
   * Metodo trae los estados para selector
   * @author Yeison David Sepulveda Ospina
   * @createdate    06-12-2024
   * @returns void {void}
   */
  getStates(): void {
    this.videcallservice.getRoomStates().subscribe(
      (res) => {
        this.state_list = res;
      },
      (error) => {
        this.state_list = [];
      }
    );
  }

  /**
   * Resetear los filtros de busqueda
   * @author Yeison David Sepulveda Ospina
   * @createdate 06-12-2024
   */
  resetFilters(): void {
    this.formFilters.reset({dateStart: '', dateEnd: '', state: '', name: ''});
    this.search();
    this.paginator.firstPage();
  }

  /**
   * Metodo abre modal para visualizar detalles de la videollamada
   * @author Yeison David Sepulveda Ospina
   * @createdate 06-12-2024
   */
  openViewHistoryDetails(id: number) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.width = '60%'
    dialogConfig.height = 'auto'
    dialogConfig.data = {
      id: id
    }
    const dialogRef = this.dialog.open(HistoryDetailsComponent, dialogConfig)
  }

  /**
	 * Método manejo y descarga el documento
   * @param data data repuesta de la api
   * @param fileName nombre descarga del documento
	 * @author Yeison David Sepulveda Ospina
	 * @createdate 06-12-2024
	 */
  fileDownload(data: any, fileName: string): void {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  /**
   * Descargar reporte de busqueda excel
   * @author Yeison David Sepulveda Ospina
   * @createdate 06-12-2024
   */
  downloadReport() {
    let dateStart = this.formFilters.get('dateStart').value;
    let dateEnd = this.formFilters.get('dateEnd').value; 
    let name = this.formFilters.get('name').value; 
    let state = this.formFilters.get('state').value; 
    const formattedDateStart = moment().tz('America/Bogota').format('YYYY-MM-DD HH:mm:ss');
    const filename = `Historical_videocall_${formattedDateStart}.xlsx`;
  
    const filters = {
      dateStart: dateStart,
      dateEnd: dateEnd,
      name: name,
      state: state,
    };
  
    this.videcallservice.downloadReport(filters).subscribe(
      (response: any) => {
        this.fileDownload(response, filename);
      },
      (error) => {
        console.error('Error en la solicitud:', error);
        this.alertService.alertError('¡Atención!', 'Ha ocurrido un error al intentar descargar el informe.');
      }
    );
  }
  
}
