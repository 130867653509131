import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MeetingsAttentionRangePaginator } from '../pages/meetings-attention-range/interfaces/meetings-attention-range.interface';

const MEETINGS_URL = environment.MEETINGS_URL;
const CHAT_SOUL = environment.CHAT_SOUL;

@Injectable({
  providedIn: 'root',
})
export class MeetingsService {
  constructor(private http: HttpClient) {}

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * Metodo que lista los asesores de meet
   * @author Yeison Sepulveda
   * @createdate 06-11-2024
   */
  getAdvisersList(): Observable<any> {
    return this.http
      .get<any>(`${MEETINGS_URL}groups/advisersList`)
      .pipe(catchError(this.handleError));
  }

  /**
   * Método que muestra todo el listado de grupos
   * @author Yeison Sepulveda
   * @createdate 06-11-2024
   */
  getGroupList(): Observable<any> {
    return this.http
      .get<any>(`${MEETINGS_URL}groups/groupList`)
      .pipe(catchError(this.handleError));
  }

  /**
   * Metodo que lista los grupos disponibles
   * @author Yeison Sepulveda
   * @createdate 06-11-2024
   * @param userId Usuario (rrhh_ids) seleccionado en la lista.
   */
  getAvailableGroups(userIds: number[]): Observable<any> {
    const body = { rrhh_ids: userIds };
    return this.http
      .post<any>(`${MEETINGS_URL}groups/availableGroups`, body)
      .pipe(catchError(this.handleError));
  }

  /**
   * Metodo que lista los grupos disponibles
   * @author Yeison Sepulveda
   * @createdate 06-11-2024
   * @param userId Usuario (rrhh_ids) seleccionado en la lista.
   */
  getAssociateGroups(userIds: number[]): Observable<any> {
    const body = { rrhh_ids: userIds };
    return this.http
      .post<any>(`${MEETINGS_URL}groups/associatedGroups`, body)
      .pipe(catchError(this.handleError));
  }

  /**
   * Metodo que guarda la asociacion entre grupos y asesores.
   * @author Yeison Sepulveda
   * @createdate 06-11-2024
   * @param userId Usuarios (rrhh_ids) seleccionado de la lista.
   * @param groupsId Grupos seleccionadas de la segunda lista.
   */
  saveGroupsUser(userId: number[], groupsId: number[]): Observable<any> {
    const body = { groups_ids: groupsId, rrhh_ids: userId };
    return this.http
      .post<any>(`${MEETINGS_URL}groups/saveGroupsUser`, body)
      .pipe(catchError(this.handleError));
  }

  /**
   * Metodo que obtiene las videollamadas vigentes
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
   */
  getVideoCalls(
    page: number,
    pageSize: number,
    rol: string,
    rrhh_id: number,
    campaign: string,
    group: string,
    adviser: string
  ): Observable<any> {
    let body = new FormData();
    body.append('page', page.toString());
    body.append('per_page', pageSize.toString());
    body.append('rol', rol);
    body.append('rrhh_id', rrhh_id.toString());

    if (campaign) {
      body.append('campaign', campaign);
    }
    if (group) {
      body.append('group', group);
    }
    if (adviser) {
      body.append('adviser', adviser);
    }
    return this.http
      .post<any>(`${MEETINGS_URL}Room/getRooms`, body)
      .pipe(catchError(this.handleError));
  }

  /**
   * Metodo que reagenda una videollamada
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
   */
  rescheduleVideoCall(data: any): Observable<any> {
    return this.http
      .post<any>(`${MEETINGS_URL}Room/rescheduleRoom`, data)
      .pipe(catchError(this.handleError));
  }
  /**
   * Metodo que cancela una videollamada
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
   */
  cancelVideoCall(data: any): Observable<any> {
    return this.http
      .post<any>(`${MEETINGS_URL}Room/cancelRoom`, data)
      .pipe(catchError(this.handleError));
  }
  /**
   * Metodo que obtiene una videollamada por id
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
   */
  getVideoCallById(id: any): Observable<any> {
    return this.http
      .get<any>(`${MEETINGS_URL}RoomBy/${id}`)
      .pipe(catchError(this.handleError));
  }
  /**
   * Metodo que obtiene los grupos
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
   */
  getGroupListPublic(): Observable<any> {
    return this.http
      .get<any>(`${MEETINGS_URL}groups/groupListPublic`)
      .pipe(catchError(this.handleError));
  }
  /**
   * Metodo que obtiene las campañas
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
   */
  getCampaigns(): Observable<any> {
    return this.http
      .get<any>(`${MEETINGS_URL}getCampaings`)
      .pipe(catchError(this.handleError));
  }
  /**
   * Metodo que obtiene los asesores y sus horarios vigentes
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
   */
  getAvailableHours(skill_id: number, campaign_id: number): Observable<any> {
    return this.http.get<any>(
      `${MEETINGS_URL}groups/getAvailableHours/${skill_id}/${campaign_id}`
    );
  }

  /**
   * Traer listado del historial de agandamientos paginado
   * @author Yeison Sepulveda
   * @createdate 06-12-2024
   * @param filter filtros para la búsqueda.
   * @param paginator configuración de paginación (pageSize y pageIndex).
   */
  getRoomsHistoryPaginate(filter: any, paginator: any): Observable<any> {
    const body = {
      ...filter,
      per_page: paginator.pageSize,
      page: paginator.pageIndex,
    };
    return this.http
      .post<any>(`${MEETINGS_URL}Room/getRoomsHistory`, body)
      .pipe(catchError(this.handleError));
  }
  /**
   * Metodo que obtiene estdos de las salas
   * @author Yeison Sepulveda
   * @createdate 06-12-2024
   */
  getRoomStates(): Observable<any> {
    return this.http
      .get<any>(`${MEETINGS_URL}Rooms/getRoomStates`)
      .pipe(catchError(this.handleError));
  }
  /**
   * Metodo que obtiene estdos de las salas
   * @author Yeison Sepulveda
   * @createdate 06-12-2024
   */
  /**
   * Descargar reporte de excel
   * @author Yeison Sepulveda
   * @createdate 2024-04-04
   * @param filters json de la informacion
   */
  downloadReport(filters) {
    return this.http
      .post<any>(`${MEETINGS_URL}Rooms/downloadReportRooms`, filters, {
        responseType: 'blob' as 'json',
      })
      .pipe(catchError(this.handleError));
  }

  /**
   * Metodo que lista los grupos disponibles
   * @author Yeison Sepulveda
   * @createdate 06-11-2024
   * @param userId Usuario (rrhh_ids) seleccionado en la lista.
   * @param id_group id grupo asociado para skill.
   */
  getAvailableSkills(userIds: number[], id_group: number): Observable<any> {
    const body = { rrhh_ids: userIds, id_group: id_group };
    return this.http
      .post<any>(`${MEETINGS_URL}skills/availableSkills`, body)
      .pipe(catchError(this.handleError));
  }

  /**
   * Metodo que lista los grupos disponibles
   * @author Yeison Sepulveda
   * @createdate 06-11-2024
   * @param userId Usuario (rrhh_ids) seleccionado en la lista.
   * @param id_group id grupo asociado para skill.
   */
  getAssociateSkills(userIds: number[], id_group: number): Observable<any> {
    const body = { rrhh_ids: userIds, id_group: id_group };
    return this.http
      .post<any>(`${MEETINGS_URL}skills/associatedSkills`, body)
      .pipe(catchError(this.handleError));
  }

  /**
   * Metodo que guarda la asociacion entre grupos y asesores.
   * @author Yeison Sepulveda
   * @createdate 06-11-2024
   * @param userId Usuarios (rrhh_ids) seleccionado de la lista.
   */
  saveSkillUser(body: any): Observable<any> {
    return this.http
      .post<any>(`${MEETINGS_URL}skills/saveSkillUser`, body)
      .pipe(catchError(this.handleError));
  }

  getCampaignsForRange<MeetingsAttentionRangeResponse>(
    body: MeetingsAttentionRangePaginator
  ) {
    return this.http
      .post<MeetingsAttentionRangeResponse>(
        `${MEETINGS_URL}settings/paginate`,
        body
      )
      .pipe(catchError(this.handleError));
  }

  updateMeetingRange(body: FormData) {
    return this.http
      .post(`${MEETINGS_URL}settings/update`, body)
      .pipe(catchError(this.handleError));
  }

  saveInfoRecording(roomId, startRecordig, endRecording) {
    return this.http
      .post(`${MEETINGS_URL}recordings/saveInfoRecording`, {
        room_id: roomId,
        start_recordig: startRecordig,
        end_recording: endRecording,
      })
      .pipe(catchError(this.handleError));
  }

  sendNotificationUserleft(roomId: string, startCallTime: Date) {
    const formattedTime = this.calculateTime(startCallTime);

    const body = {
      room_id: roomId,
      flag: 0,
      time: formattedTime,
    };

    return this.http
      .post(`${MEETINGS_URL}leavingRoom`, body)
      .pipe(catchError(this.handleError));
  }

  calculateTime(startCallTime: Date) {
    const start = new Date(startCallTime);
    const end = new Date();

    // Se hace el calculo de la diferencia en milisegundos
    const difference = end.getTime() - start.getTime();

    // Se convierte la diferencia a horas, minutos y segundos
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  // Función auxiliar para agregar ceros a la izquierda si es necesario
  private padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
}
