<div class="container-fluid p-5">
  <div class="row">
    <div class="col-md-12">
      <span style="font-size: 32px; font-weight: 700;">Gestión de Skills</span>
    </div>
    <div class="col-md-12 mt-4">
      <p class="lh-base" style="color: #353535">
        En este espacio, puedes asignar o remover skills para los asesores de videollamadas, <br>
        facilitando una organización efectiva y personalizada. No olvides: “El respeto <br> y la empatía son clave”.
      </p>
    </div>
  </div>
  <div class="row justify-content-between">
    <div class="container-fluid">
      <div class="row my-5 justify-content-start">
        <div class="col-md-4">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Buscar usuarios</mat-label>
            <input type="text" matInput [formControl]="userSearchControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let user of filteredUsers" [value]="user">
                <div (click)="optionClicked($event, user)">
                  <mat-checkbox [checked]="user.selected" (change)="toggleSelection(user)" (click)="$event.stopPropagation()">
                    <span style="font-weight: normal;">{{ user.candidate_name }}</span>
                  </mat-checkbox>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Selecciona grupo</mat-label>
            <mat-select [formControl]="groupFilter">
              <mat-option *ngFor="let group of groups" [value]="group.id">
                {{ group.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2">
          <button class="heaight-buttons w-100"  matTooltip='Buscar' matTooltipPosition="above" mat-flat-button color="primary"  (click)="getInfoUserSelection()" [disabled]="!(groupFilter.value && userSelected.length)">
            Buscar &nbsp; <i class="fi-rr-search"></i>
        </button>
        </div>
      </div>

      <app-exchange-lists
      *ngIf="showExchangeLists && userSelected.length"
        [taskDescription]="'Nota: Recuerda que para asignar skills a los usuarios, deberás seleccionarlas con los checks del lado izquierdo (skills disponibles) y luego dar clic en el botón de la flecha para pasarlas al lado derecho (skills asignados).'"
        [titleFirstList]="'Skills disponibles'" [titleSecondList]="'Skills asignadas'"
        [textInputFirstList]="'Buscar skills disponibles'" [textInputSecondList]="'Buscar skills asignados'"
        [firstList]="firstList" [secondList]="secondList" (runFilter)="runFilterList($event)"
        (updateList)="updateList($event)" (saveConfig)="saveConfig($event)" (cancelProcess)="cancelProcess()">
      </app-exchange-lists>
    </div>
  </div>
</div>



