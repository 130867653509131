import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MeetingsService } from '../../services/meetings.service';
import { AttentionRangeFormComponent } from './dialogs/attention-range-form/attention-range-form.component';
import {
  MeetingsAttentionRangeInterface,
  MeetingsAttentionRangePaginator,
  MeetingsAttentionRangeResponse,
} from './interfaces/meetings-attention-range.interface';

@Component({
  selector: 'app-meetings-attention-range',
  templateUrl: './meetings-attention-range.component.html',
  styleUrls: ['./meetings-attention-range.component.sass'],
})
export class MeetingsAttentionRangeComponent implements OnInit {
  // Initializated variables
  campaigns: any[] = [];
  displayedColumns: string[] = [
    'actions',
    'id',
    'campaign_name',
    'interval_rooms',
    'background_url',
  ];

  // Table Data
  page: number = 1;
  length: number = 0;
  pageSize: number = 5;
  pageSizeOptions: number[] = [5, 10, 50, 100];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private meetingsService: MeetingsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setCampaigns();
  }

  /**
   * Metodo que obtiene la lista de campañas de la base de datos
   * @author Sergio Cortés
   * @createdate 26-12-2024
   */
  setCampaigns(): void {
    const paginator: MeetingsAttentionRangePaginator = {
      per_page: this.pageSize,
      page: this.page,
    };

    this.meetingsService.getCampaignsForRange(paginator).subscribe({
      next: (resp: MeetingsAttentionRangeResponse) => {
        this.dataSource = new MatTableDataSource(resp.data);
        this.length = resp.data.length;
        this.dataSource.sort = this.sort;
      },
    });
  }

  /** Método que controla la paginación de la tabla
   * @author Sergio Cortés
   * @createdate 26-12-2024
   */
  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.length = event.length;
  }

  openRescheduleForm(data: MeetingsAttentionRangeInterface) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '70%';
    dialogConfig.height = 'auto';
    dialogConfig.data = { data };

    const dialogRef = this.dialog.open(
      AttentionRangeFormComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.updated) this.setCampaigns();
      }
    });
  }
}
