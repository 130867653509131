<div class="main">
  <div class="pn-lateral-56-24 mtn-64">
    <div class="col-12">
      <h1>Agendar Videollamada</h1>
    </div>
    <div class="col-6">
      <p class="lh-base" style="color: #353535">
        Aquí podrás agendar tus videollamadas. No olvides, “El respeto y empatía son clave”.
      </p>
    </div>
    <form [formGroup]="callRequest">
      <div class="row m-3">
        <!-- Nombre empresa cliente -->
        <mat-form-field class="p-3 col-12 col-md-6 col-lg-4" appearance="fill">
          <mat-label>Nombre de la empresa</mat-label>
          <input formControlName="company_name" matInput maxlength="100">
          <mat-error *ngIf="callRequest.controls['company_name'].hasError('required')"> Campo requerido.</mat-error>
        </mat-form-field>

        <!-- NIT empresa cliente -->
        <mat-form-field class="p-3 col-12 col-md-6 col-lg-4" appearance="fill">
          <mat-label>NIT</mat-label>
          <input type="text" formControlName="NIT" matInput (input)="stripText('NIT')" maxlength="15">
          <mat-error *ngIf="callRequest.controls['NIT'].hasError('required')"> Campo requerido.</mat-error>
          <mat-error *ngIf="callRequest.controls['NIT'].hasError('maxLength')"> Debe contener máximo 15 carateres.
          </mat-error>
        </mat-form-field>

        <!-- Codigo distrito cliente -->
        <mat-form-field class="p-3 col-12 col-md-6 col-lg-4" appearance="fill">
          <mat-label>Codigo del distrito</mat-label>
          <input formControlName="distric_code" matInput maxlength="20">
          <mat-error *ngIf="callRequest.controls['distric_code'].hasError('required')"> Campo requerido.</mat-error>
        </mat-form-field>

        <!-- Nombre cliente -->
        <mat-form-field class="p-3 col-12 col-md-6 col-lg-4" appearance="fill">
          <mat-label>Nombre del solicitante.</mat-label>
          <input formControlName="name" matInput maxlength="100">
          <mat-error *ngIf="callRequest.controls['name'].hasError('required')"> Campo requerido.</mat-error>
        </mat-form-field>

        <!-- N documento cliente -->
        <mat-form-field class="p-3 col-12 col-md-6 col-lg-4" appearance="fill">
          <mat-label>Número de documento</mat-label>
          <input formControlName="document" matInput maxlength="15">
          <mat-error *ngIf="callRequest.controls['document'].hasError('required')" (input)="stripText('document')">
            Campo requerido.</mat-error>
          <mat-error *ngIf="callRequest.controls['document'].hasError('maxLength')"> Debe contener máximo 15
            carateres.</mat-error>
        </mat-form-field>

        <!-- Tipo llamda -->
        <mat-form-field class="p-3 col-12 col-md-6 col-lg-4" appearance="fill">
          <mat-label>Tipo de videollamada</mat-label>
          <mat-select formControlName="typeCall">
            <mat-option *ngFor="let item of options" [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Selección de grupo -->
        <mat-form-field class="p-3 col-12 col-md-6 col-lg-4" appearance="fill">
          <mat-label>Grupo</mat-label>
          <mat-select formControlName="group" (selectionChange)="onGroupChange($event)">
            <mat-option *ngFor="let group of groups" [value]="group.id">
              {{ group.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Selección de skills -->
        <mat-form-field *ngIf="isGroupSelected"
        class="p-3 col-12 col-md-6 col-lg-4" appearance="fill">
          <mat-label>Skills</mat-label>
          <mat-select formControlName="skill" (selectionChange)="onSkillsChange($event)">
            <mat-option *ngFor="let skill of skills" [value]="skill.id">
              {{ skill.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Selección de agente -->
        <mat-form-field *ngIf="callRequest.controls['typeCall'].value == 2 && isGroupSelected"
          class="p-3 col-12 col-md-6 col-lg-4" appearance="fill">
          <mat-label>Seleccionar Agente</mat-label>
          <mat-select formControlName="agent" (selectionChange)="onAgentChange($event)">
            <mat-option *ngFor="let agent of agents" [value]="agent.rrhh_id">
              {{ agent.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Selección de Fecha -->
        <mat-form-field *ngIf="callRequest.controls['typeCall'].value == 2 && isAdiviserSelected"
        class="p-3 col-12 col-md-6 col-lg-4" appearance="fill">
          <mat-label>Fecha</mat-label>
          <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" [matDatepickerFilter]="dateFilter"
            formControlName="calendarr" (dateChange)="onDateChange($event)"
            [disabled]="callRequest.controls['typeCall'].value !== 2">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <!-- Selección de Hora -->
        <mat-form-field *ngIf="callRequest.controls['typeCall'].value == 2 && isAdiviserSelected"
          class="p-3 col-12 col-md-6 col-lg-4" appearance="fill">
          <mat-label>Seleccionar Hora</mat-label>
          <mat-select formControlName="time" [disabled]="callRequest.controls['typeCall'].value !== 2">
            <mat-option *ngFor="let schedule of filteredSchedules" [value]="schedule">{{ schedule | date: 'shortTime'
              }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Adjuntar documento -->
        <mat-form-field class="p-3 col-12 col-md-6 col-lg-4" appearance="fill">
          <mat-label>Adjuntar archivo</mat-label>
          <input type="text" placeholder="Selecciona un archivo" matInput readonly formControlName="filename"
            (click)="fileInput.click()" />
          <input #fileInput type="file" hidden (change)="onChargeFile($event)" />
          <mat-icon matSuffix [class.disabled-icon]="callRequest.disabled"
            (click)="!callRequest.disabled && fileInput.click()">
            attach_file
          </mat-icon>
        </mat-form-field>

        <!-- Correo -->
        <mat-form-field class="p-3 col-12 col-md-6 col-lg-4" appearance="fill">
          <mat-label>Correo</mat-label>
          <input type="email" formControlName="email" matInput>
          <mat-error *ngIf="callRequest.controls['email'].hasError('required')"> Campo requerido.</mat-error>
          <mat-error *ngIf="callRequest.controls['email'].hasError('pattern')"> Correo inválido </mat-error>
        </mat-form-field>

        <!-- telefono -->
        <mat-form-field class="p-3 col-12 col-md-6 col-lg-4" appearance="fill">
          <mat-label>Teléfono</mat-label>
          <input type="text" formControlName="phone" matInput (input)="stripText('phone')" minlength="10"
            maxlength="10">
          <mat-error *ngIf="callRequest.controls['phone'].hasError('required')"> Campo requerido-</mat-error>
          <mat-error *ngIf="callRequest.controls['phone'].hasError('minLength') || phone"> Debe contener mínimo 10
            carateres. </mat-error>
          <mat-error *ngIf="callRequest.controls['phone'].hasError('maxLength')"> Debe contener máximo 10 carateres.
          </mat-error>
          <mat-error *ngIf="callRequest.controls['phone'].hasError('pattern')"> Teléfono invalido solo se aceptan
            números.</mat-error>
        </mat-form-field>
        <mat-error style="font-size: 11px !important; margin-top: -20px; margin-left: 15px;"
          *ngIf="checkHour == true && callRequest.controls['calendar'].touched"> La hora seleccionada debe ser mayor a
          la actual
        </mat-error>
      </div>
    </form>
  </div>
  <div class="col-md-12 p-5 pt-0">
    <div class="row py-3 d-flex justify-content-end">
      <div class="col-md-3">
        <button [disabled]="callRequest.invalid || disableButton" (click)="send()" type="button" mat-raised-button color="primary" class="w-100" style="height: 48px; font-weight: 600;">Guardar</button>
      </div>
    </div>
  </div>
</div>

