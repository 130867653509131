import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicSnackbarComponent } from 'src/app/shared/basic-snackbar/basic-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class AlertsSnackbarService {

  constructor(private matSnackBar: MatSnackBar) { }

  /**
    * @author Fabian Duran
    * @createdate 2024-07-16
    * Metodo que muestra una notificacion tipo success.
    * @param title Titulo de la notificacion.
    * @param message Mensaje de la notificacion.
    * @param duration Duracion de la notificacion.
    * @param panelClass Clase CSS de la notificacion.
  */
  showNotification({title = '¡Excelente!', message, duration = 5000, panelClass = 'green-snackbar'}): void {
    this.matSnackBar.openFromComponent(BasicSnackbarComponent, {
      data: { title, message },
      duration,
      verticalPosition: 'top',
      panelClass: [panelClass]
    });
  }
}