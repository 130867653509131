import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { VicidialService } from '../services/vicidal.service';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { RecordDialogComponent } from './dialogs/record-dialog/record-dialog.component';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/shared/basic-crud-page-service/crud.service';

const { VICIDIAL_URL } = environment;

@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.sass'],
})
export class RecordsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  filters: FormGroup;
  tableColumns: string[] = [
    'actions',
    'document',
    'agente',
    'cedula_cliente',
    'nombre_cliente',
    'phone_number',
    'created_at',
    'minutos',
  ];
  length: number;
  pageSize: number = 5;
  pageSizeOptions: number[] = [5, 10, 50, 100];
  page: number = 1;
  records_list: any[] = [];
  campaings: any[] = [];
  operations: any[] = [];
  user: number = null;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private alertService: AlertsService,
    private vicidialService: VicidialService,
    private authService: AuthService,
    private crudService: CrudService
  ) {}

  ngOnInit(): void {
    // this.getInfo(this.page, this.pageSize);
    this.user = this.authService.getUser().rrhh_id;
    this.initializeForm();
    this.getCampaings();
    this.getOperations();
  }

  initializeForm() {
    this.filters = this.formBuilder.group({
      start_date: ['', []],
      end_date: ['', []],
      campania: [null, []],
      operation: [null, []],
      agente_id: [null, []],
      cliente_nombre: [null, []],
      cliente_id: [null, []],
    });
  }

  paginatorEvent(event: any) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getInfo(
      this.page,
      this.pageSize,
      this.filters.get('start_date').value,
      this.filters.get('end_date').value,
      this.filters.get('campania').value,
      this.filters.get('operation').value,
      this.filters.get('agente_id').value,
      this.filters.get('cliente_nombre').value,
      this.filters.get('cliente_id').value
    );
  }

  getInfo(
    page: number,
    pageSize: number,
    start_date: string = '',
    end_date: string = '',
    campaing: number = null,
    operation: number = null,
    agente_id: string = null,
    cliente_nombre: string = null,
    cliente_id: string = null
  ) {
    const start = start_date ? moment(start_date).format('YYYY-MM-DD') : '';
    const end = end_date ? moment(end_date).format('YYYY-MM-DD') : '';
    this.vicidialService
      .getSoulphoneRecords(
        page,
        pageSize,
        start,
        end,
        campaing,
        operation,
        agente_id,
        cliente_nombre,
        cliente_id
      )
      .subscribe((resp: any) => {
        this.records_list = resp.data?.data;
        this.length = resp.data?.total;
        this.pageSize = resp.data?.per_page;
      });
  }

  filterInfo() {
    this.page = 1;
    this.getInfo(
      this.page,
      this.pageSize,
      this.filters.get('start_date').value,
      this.filters.get('end_date').value,
      this.filters.get('campania').value,
      this.filters.get('operation').value,
      this.filters.get('agente_id').value,
      this.filters.get('cliente_nombre').value,
      this.filters.get('cliente_id').value
    );
    this.paginator.firstPage();
  }

  resetFilters() {
    this.page = 1;
    this.filters.patchValue({
      start_date: '',
      end_date: '',
      campania: null,
      operation: null,
      agente_id: null,
      cliente_id: null,
      cliente_nombre: null,
    });
    // this.getInfo(this.page, this.pageSize)
    this.paginator.firstPage();
  }

  formDialog(url: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'dialogCrudCategory';
    dialogConfig.width = '50%';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      url: url,
    };
    const dialogRef = this.dialog.open(RecordDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  getCampaings(): void {
    this.crudService
      .getData({ url: `${VICIDIAL_URL}v2/user-campaigns-by-rrhh/${this.user}` })
      .subscribe((res) => {
        this.campaings = res;
      });
  }

  getOperations(): void {
    this.crudService
      .getData({ url: `${VICIDIAL_URL}v2/operation-user-by-rrhh/${this.user}` })
      .subscribe((res) => {
        this.operations = res;
      });
  }
}
