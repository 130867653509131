import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersGroupComponent } from './pages/users-group/users-group.component';
import { MeetingsModuleComponent } from './meetings.component';
import { DashboardVideocallComponent } from '../livechat/modules/chat/pages/dashboard-videocall/dashboard-videocall.component';
import { MeetingsAdminComponent } from './pages/meetings-admin/meetings-admin.component';
import { MeetingHistoryComponent } from './pages/meeting-history/meeting-history.component';
import { MeetingRequestComponent } from './pages/meeting-request/meeting-request.component';
import { UsersSkillComponent } from './pages/users-skill/users-skill.component';
import { MeetingsAttentionRangeComponent } from './pages/meetings-attention-range/meetings-attention-range.component';


const routes: Routes = [
  { path: '', component:  MeetingsModuleComponent },
  { path: 'usergroup_management', component: UsersGroupComponent},
  { path: 'userskill_management', component: UsersSkillComponent},
  { path: 'dashboard', component: DashboardVideocallComponent},
  { path: 'meetings-admin', component: MeetingsAdminComponent},
  { path: 'meetings-history', component: MeetingHistoryComponent},
  { path: 'meetings-request', component: MeetingRequestComponent},
  { path: 'meetings-attention-range', component: MeetingsAttentionRangeComponent },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MeetingRoutingModule { }
