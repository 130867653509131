import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { VideollamadaService } from 'src/app/shared/videollamada/services/videollamada.service';
import Swal from 'sweetalert2';
import { ChatMeetingsService } from '../../services/chat.service';

@Component({
  selector: 'app-meeting-request',
  templateUrl: './meeting-request.component.html',
  styleUrls: ['./meeting-request.component.sass']
})
export class MeetingRequestComponent implements OnInit {


  callRequest!: FormGroup;
  actualDate: any;
  options: any[] = [{ id: 1, name: 'Inmediata' }, { id: 2, name: 'Programada' }];
  groups: any[] = [];
  skills: any[] = [];
  currentDateTime: any;
  Hours: any[] = [];
  checkHour: boolean = true;
  currentHour = new Date();
  disableButton: boolean = false;
  currentYear = moment().tz('America/Bogota').format('YYYY');
  phone: boolean = false;
  advisorSchedules: any[] = [];
  agents: any[] = [];
  selectedAgentSchedules: string[] = [];
  selectedDate: Date | null = null;
  filteredSchedules: string[] = [];
  today: string = new Date().toISOString().split('T')[0];
  minDate: Date;
  maxDate: Date;
  isGroupSelected: boolean = false;
  isAdiviserSelected: boolean = false;
  rrhh_id: string = '';
  campaign_id: any;
  config_id: any;


  constructor(
    private service: VideollamadaService,
    private serviceMeetigns: ChatMeetingsService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private router: Router,
    private authService: AuthService
  ) {
    this.startForm();
  }

  ngOnInit() {

    const user = this.authService.getUser();
    this.rrhh_id = user.rrhh_id;

    this.service.getConfigUser(this.rrhh_id).subscribe(resp => {
      this.config_id = resp.id;
      this.campaign_id = resp.campaign_id;
      this.getSchedule(this.campaign_id );
    }, error => {
      console.log(error);
      Swal.fire(
        'Error',
        'Hubo un problema al enviar la información. Intente nuevamente.',
        'error'
      );
    });

  }

  /**
	 * Obtener los horarios de atancion
	 * @author Yeison Sepulveda
	 * @createdate 2024-11-07
	 */
    getSchedule(campaign_id: any ) {
      this.minDate = this.getStartOfWeek();
      this.maxDate = this.getEndOfWeek();

      var curdate = new Date();
      curdate.setMinutes(10);
      let format = moment(curdate).tz('America/Bogota').format('YYYY-MM-DDTHH:mm');
      this.actualDate = format;

      this.service.getScheduleByCampaign(campaign_id).subscribe(res => {
        this.Hours = res.Hours ? res.Hours : [];
        if (res.Hours.length == 0 || res.Hours == []) {
          this.callRequest.disable();
        } else {
          this.currentHour.setMinutes(this.currentHour.getMinutes() + 5);
          const actualHour = moment(this.currentHour).tz('America/Bogota').format('HH:mm');
          this.Hours = this.Hours.filter(element => moment(element).format('HH:mm') > actualHour);
          this.getGroups();
        }

      }, error => {
        this.callRequest.disable();
      });

      this.callRequest.controls['typeCall'].valueChanges.subscribe(resp => {
        if (resp == 1) {
          this.callRequest.controls['calendar'].clearValidators();
          this.callRequest.controls['calendar'].updateValueAndValidity();

          this.callRequest.controls['agent'].clearValidators();
          this.callRequest.controls['agent'].updateValueAndValidity();

          this.callRequest.controls['calendarr'].clearValidators();
          this.callRequest.controls['calendarr'].updateValueAndValidity();

          this.callRequest.controls['time'].clearValidators();
          this.callRequest.controls['time'].updateValueAndValidity();

          var date = new Date();
          date.setMinutes(date.getMinutes() + 5);
          this.currentDateTime = moment(date).tz('America/Bogota').format('YYYY-MM-DDTHH:mm');
          this.checkHour = false;
        } else {
          //this.callRequest.controls['calendar'].setValidators([Validators.required]);
          //this.callRequest.controls['calendar'].updateValueAndValidity();

          this.callRequest.controls['agent'].setValidators([Validators.required]);
          this.callRequest.controls['agent'].updateValueAndValidity();

          this.callRequest.controls['calendarr'].setValidators([Validators.required]);
          this.callRequest.controls['calendarr'].updateValueAndValidity();

          this.callRequest.controls['time'].setValidators([Validators.required]);
          this.callRequest.controls['time'].updateValueAndValidity();

        }
      });


      this.callRequest.controls['time'].valueChanges.subscribe(resp => {
        const date = new Date();
        const selectedDate = moment(resp);
        const actualDate = moment(date).tz('America/Bogota');

        if (selectedDate.isAfter(actualDate)) {
          this.checkHour = false;
        } else {
          this.checkHour = true;
        }
      });

      this.callRequest.controls['phone'].valueChanges.subscribe(res => {
        res.length < 10 ? this.phone = true : this.phone = false;
      });
    }

  startForm() {
    this.callRequest = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$")]),
      phone: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]),
      NIT: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      observations: new FormControl('', []),
      typeCall: new FormControl('', [Validators.required]),
      calendar: new FormControl('', []),
      filename: new FormControl('', []),
      file: new FormControl('', []),
      group: new FormControl('', [Validators.required]),
      skill: new FormControl('', [Validators.required]),
      agent: new FormControl('', []),
      time: new FormControl('', []),
      calendarr: new FormControl('', []),
      company_name: new FormControl('', [Validators.required]),
      document: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      distric_code: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),

    });
  }

  send() {

    const time = this.callRequest.controls['time'].value;
    const programTime = moment(time).tz('America/Bogota').format('YYYY-MM-DDTHH:mm');

    const dateRoom = this.callRequest.controls['typeCall'].value == 2 ? programTime : this.currentDateTime;
    const roomName = this.callRequest.controls['NIT'].value + '-' + moment(dateRoom).format('YYYY-MM-DD');
    const emailU = this.callRequest.controls['email'].value
    const phoneU = this.callRequest.controls['phone'].value

    const body: FormData = new FormData()
    body.append('client_name', this.callRequest.controls['name'].value);
    body.append('client_email', emailU);
    body.append('client_nic', this.callRequest.controls['NIT'].value);
    body.append('client_phone', phoneU);
    body.append('client_observation', this.callRequest.controls['observations'].value);
    body.append('scheduled_date', this.callRequest.controls['typeCall'].value == 2 ? programTime : this.currentDateTime);
    body.append('name', roomName);
    body.append('type_id', this.callRequest.controls['typeCall'].value.toString());
    body.append('campaign_id', this.campaign_id);
    body.append('persist', '1');
    body.append('config_id', this.config_id);
    body.append('origin_type', '1');
    body.append('id_group', this.callRequest.controls['group'].value.toString());
    body.append('id_skill', this.callRequest.controls['skill'].value.toString());
    body.append('adviser_id', this.callRequest.controls['agent'].value);
    body.append('company_name', this.callRequest.controls['company_name'].value.toString());
    body.append('nit', this.callRequest.controls['document'].value.toString());
    body.append('distric_code', this.callRequest.controls['distric_code'].value.toString());

    const fileControl = this.callRequest.controls['file'].value;
    if (fileControl instanceof File) {
      body.append('file', fileControl, fileControl.name)
    }

    this.disableButton = true;
    this.spinner.show();
    this.service.sendFormMeetings(body).subscribe(resp => {
      if (this.callRequest.controls['typeCall'].value == 1) {
        Swal.fire({
          title: '¡Videollamada Creada!',
          text: 'Comparte este enlace de tu videollamada, para que el cliente ingrese:',
          input: 'text',
          inputValue: resp.link,
          showCancelButton: true,
          confirmButtonText: 'Copiar Enlace',
          cancelButtonText: 'Cerrar',
          confirmButtonColor: '#2CABBC',
          cancelButtonColor: '#FFFFFF',
          showLoaderOnConfirm: true,
          reverseButtons: true,
          preConfirm: () => {
            navigator.clipboard.writeText(resp.link);
          },
        });
      } else {
        Swal.fire(
          '¡Videollamada Programada!',
          'La videollamada fue programada exitosamente. Se enviará la notificación correspondiente.',
          'success'
        );
      }

      this.callRequest.reset();
    }, error => {
      console.log(error);
      Swal.fire(
        'Error',
        'Hubo un problema al enviar la información. Intente nuevamente.',
        'error'
      );
    });
  }

  stripText(control: string) {
    this.callRequest.controls[control].setValue(this.callRequest.controls[control].value.replace(/[^0-9]/g, ''));
  }

  /**
   * @author Juan Carlos Alonso
   * @createdate 22-10-2024
   * @param event Evento de archivo seleccionado
   * Método que obtiene el evento de la carga del archivo y asigna su valor al control en el formulario reactivo
   */
  onChargeFile(event: any): void {
    const file: File = event.target.files[0];

    if (file) {
      const maxSizeInMB = 10;
      if (file.size > maxSizeInMB * 1024 * 1024) {
        Swal.fire({
          icon: 'error',
          title: 'Archivo demasiado grande',
          text: 'El archivo debe tener un tamaño máximo de 10 MB.',
          timer: 4000,
          timerProgressBar: true,
          showConfirmButton: false
        });
        return;
      }

      const allowedTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        'image/jpeg',
        'image/png'
      ];

      if (!allowedTypes.includes(file.type)) {
        Swal.fire({
          icon: 'error',
          title: 'Formato no permitido',
          text: 'Solo se permiten archivos PDF, Word, Excel, TXT, JPEG y PNG.',
          timer: 4000,
          timerProgressBar: true,
          showConfirmButton: false
        });
        return;
      }

      this.callRequest.get('filename').setValue(file.name);
      this.callRequest.get('file').setValue(file);
      this.callRequest.updateValueAndValidity();
    }
  }

  /**
   * @author Yeison David Sepulveda Ospina
   * @createdate 14-11-2024
   * Obtener los grupos
   */
  getGroups(): void {
    this.service.getGroups().subscribe(res => {
      this.groups = res.groups;
    }, error => {
      console.error('Error al obtener los grupos');
    });
  }

  /**
   * @param event Evento que contiene el valor del grupo seleccionado
   * Método que se ejecuta cuando se cambia el grupo seleccionado
  */
  onGroupChange(event: any): void {
    const group = event.value;
    this.isGroupSelected = !!group;
    this.serviceMeetigns.getSkills(group).subscribe(
      (res) => {
        console.log(res)
        this.skills= res.groups;
        this.callRequest.controls['agent'].enable();
      },
      (error) => {
        console.error('Error al consultar la API:', error);
        this.callRequest.controls['agent'].disable();
      }
    );
  }

  /**
   * @param event Evento que contiene el valor del grupo seleccionado
   * Método que se ejecuta cuando se cambia el skill seleccionado
  */
  onSkillsChange(event: any): void {
    const group = event.value;
    this.callRequest.controls['agent'].disable();
    this.service.getAvailableHours(group, this.campaign_id).subscribe(
      (res) => {
        this.advisorSchedules = res;
        this.agents = this.advisorSchedules.map(agent => ({
          name: agent.name,
          schedules: agent.schedules,
          rrhh_id: agent.rrhh_id,
        }));

        this.callRequest.controls['agent'].enable();
      },
      (error) => {
        console.error('Error al consultar la API:', error);
        this.advisorSchedules = null;

        this.callRequest.controls['agent'].disable();
      }
    );
  }

  /**
   * @author Yeison David Sepulveda Ospina
   * @createdate 14-11-2024
   * @param event Evento que contiene el nombre del agente seleccionado
   * Método que se ejecuta cuando se cambia el agente seleccionado
   */
  onAgentChange(event: any): void {
    const agentName = event.value;
    this.isAdiviserSelected = !!agentName;
    const selectedAgent = this.agents.find(agent => agent.rrhh_id === agentName);
    this.selectedAgentSchedules = selectedAgent ? selectedAgent.schedules : [];
    this.callRequest.controls['calendarr'].reset();
    this.callRequest.controls['time'].reset();
  }

  /**
   * @author Yeison David Sepulveda Ospina
   * @createdate 14-11-2024
   * @param event Evento que contiene la fecha seleccionada
   * Método que se ejecuta cuando se selecciona una fecha
   */
  onDateChange(event: any): void {
    const selectedDate: Date = event.value;
    this.selectedDate = selectedDate;

    if (this.selectedDate) {
      const selectedDateString = moment(this.selectedDate).format('YYYY-MM-DD');

      const dayOfWeek = this.selectedDate.getDay();
      const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;

      if (this.selectedAgentSchedules) {
        const daySchedules = this.selectedAgentSchedules[adjustedDayOfWeek.toString()];

        if (daySchedules) {
          this.filteredSchedules = daySchedules.filter(schedule => {
            const [scheduleDate, scheduleTime] = schedule.split(' ');
            const scheduleDateTime = moment(`${scheduleDate} ${scheduleTime}`, 'YYYY-MM-DD HH:mm').tz('America/Bogota');

            return scheduleDate === selectedDateString && scheduleDateTime.isSameOrAfter(moment());
          });
        }
      }
    }
  }

  /**
   * @author Yeison David Sepulveda Ospina
   * @createdate 14-11-2024
   * @returns {Date} - Fecha correspondiente al lunes de la semana actual.
   * Obtener el lunes de la semana actual.
   */
  getStartOfWeek(): Date {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day == 0 ? -6 : 1);
    const startOfWeek = new Date(today.setDate(diff));
    startOfWeek.setHours(0, 0, 0, 0);
    return startOfWeek;
  }

  /**
   * @author Yeison David Sepulveda Ospina
   * @createdate 14-11-2024
   * @returns {Date} - Fecha correspondiente al domingo de la semana actual.
   * Obtener el domingo de la semana actual.
   */
  getEndOfWeek(): Date {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day == 0 ? 0 : 7);
    const endOfWeek = new Date(today.setDate(diff));
    endOfWeek.setHours(0, 0, 0, 0);
    return endOfWeek;
  }

  /**
   * @author Yeison David Sepulveda Ospina
   * @createdate 14-11-2024
   * @param {Date | null} date - Fecha que será evaluada.
   * @returns {boolean}
   * Filtro de fechas para habilitar solo los días de la semana actual.
   */
  dateFilter = (date: Date | null): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (!date) return false;
    const dayOfWeek = date.getDay();
    const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
    const daySchedules = this.selectedAgentSchedules ? this.selectedAgentSchedules[adjustedDayOfWeek.toString()] : [];
    const isDayAvailable = daySchedules && daySchedules.length > 0;

    return date >= today && date <= this.maxDate && isDayAvailable;
  };

}
