import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { VICIDIAL_URL } = environment;

@Injectable({
  providedIn: 'root',
})
export class VicidialService {
  constructor(private http: HttpClient) {}

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }
  /**
   * @author Juan Carlos Alonso
   * @purpose Obtiene el listado de las grabaciones de soulphone.
   * @createdate 25-11-2024
   */
  getSoulphoneRecords(
    page: number,
    per_page: number,
    start_date: string = '',
    end_date: string = '',
    campaing: number = null,
    operation: number = null,
    agente_id: string = null,
    cliente_nombre: string = null,
    cliente_id: string = null
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('perPage', per_page.toString());

    if (start_date) params = params.set('start', start_date);
    if (end_date) params = params.set('end', end_date);
    if (campaing) params = params.append('campaigns', campaing.toString());
    if (operation) params = params.append('operations', operation.toString());
    if (agente_id) params = params.append('agente_id', agente_id);
    if (cliente_id) params = params.append('cliente_id', cliente_id);
    if (cliente_nombre)
      params = params.append('cliente_nombre', cliente_nombre);

    return this.http
      .get<any>(`${VICIDIAL_URL}listRecord/all?`, { params })
      .pipe(catchError(this.handleError));
  }
}
