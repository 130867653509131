<app-basic-crud-page
  [titlePage]="'Grabaciones de videollamadas'"
  [descriptionPage]="
    'En este espacio podrás ver, reproducir y descargar las videollamadas generadas en el sistema.'
  "
  [textInputFilter]="'Buscar por NIT del cliente'"
  [textInputFilter2]="'Buscar por nombre del cliente'"
  [textInputFilter3]="'Buscar por ID del agente'"
  [showFilterText2]="true"
  [showFilterText3]="true"
  [configColumnsTable]="configColumnsTable"
  [configPaginator]="configPaginator"
  [FilterDateMaxNow]="max_date"
  [dataTable]="videoRecords"
  [showFilterDate]="true"
  [filterRangeDate]="true"
  [customizeButtonsTable]="[{ class: 'fi-rr-eye', action: 'view' }]"
  (changeFilter)="onChangeFilter($event, 1)"
  (changeFilter2)="onChangeFilter($event, 2)"
  (changeFilter3)="onChangeFilter($event, 3)"
  (updatePaginator)="onChangePage($event)"
  (onChangeRangeDateFilter)="onChangeFilterRangeDate($event)"
  (onClickCustomizeButtonsTable)="onClickShowVideoRecord($event)"
  [showClearFilters]="true"
  (onClickAditionalButton)="onClickCelarFiltersButton()"
>
</app-basic-crud-page>
