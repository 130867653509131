import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialModule } from '../../shared/material/material.module';
import { SharedModule } from '../../shared/shared.module';
import { HistoryDetailsComponent } from './components/history-details/history-details.component';
import { MeetingRoutingModule } from './meetings-routing.module';
import { MeetingHistoryComponent } from './pages/meeting-history/meeting-history.component';
import { MeetingRequestComponent } from './pages/meeting-request/meeting-request.component';
import { RescheduleFormComponent } from './pages/meetings-admin/dialogs/reschedule-form/reschedule-form.component';
import { MeetingsAdminComponent } from './pages/meetings-admin/meetings-admin.component';
import { UsersGroupComponent } from './pages/users-group/users-group.component';
import { UsersSkillComponent } from './pages/users-skill/users-skill.component';
import { AttentionRangeFormComponent } from './pages/meetings-attention-range/dialogs/attention-range-form/attention-range-form.component';
import { MeetingsAttentionRangeComponent } from './pages/meetings-attention-range/meetings-attention-range.component';

@NgModule({
  declarations: [
    AttentionRangeFormComponent,
    HistoryDetailsComponent,
    MeetingHistoryComponent,
    MeetingRequestComponent,
    MeetingsAdminComponent,
    MeetingsAttentionRangeComponent,
    RescheduleFormComponent,
    UsersGroupComponent,
    UsersSkillComponent,
  ],
  imports: [
    CommonModule,
    MeetingRoutingModule,
    MaterialModule,
    MatDialogModule,
    SharedModule,
    MatSortModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatChipsModule,
    MatTooltipModule,
  ],
})
export class MeetingsModule {}
