<div class="main">
  <div class="pn-lateral-56-24 mtn-64">
    <div class="col-12">
      <h1>Grabaciones De Soulphone</h1>
    </div>
    <div class="col-4">
      <p>En este espacio podrás consultar y reproducir las grabaciones de soulphone. No olvides, "El respeto y la
        empatía son claves".</p>
    </div>
    <form [formGroup]="filters" class="mt-4">
      <div class="row">
        <div class="col-12 col-md-4">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Rango de fechas</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate formControlName="start_date" placeholder="Inicio">
              <input matEndDate formControlName="end_date" placeholder="Fin">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-4">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Campañas</mat-label>
            <mat-select formControlName="campania">
              <mat-option *ngFor="let item of campaings" [value]="item.name">{{ item.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-4">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Operaciones</mat-label>
            <mat-select formControlName="operation">
              <mat-option *ngFor="let item of operations" [value]="item.name">{{ item.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-3">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Id del agente</mat-label>
            <input matInput formControlName="agente_id" placeholder="Ingresa la id del agente"/>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-3">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Documento del cliente</mat-label>
            <input matInput formControlName="cliente_id" placeholder="Ingresa la id del cliente"/>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-3">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Nombre del cliente</mat-label>
            <input matInput formControlName="cliente_nombre" placeholder="Ingresa el nombre del cliente"/>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-4">
          <button class="me-3 action-buttons" mat-raised-button color="primary" (click)="filterInfo()"><mat-icon><i
                class="fi-rr-search"></i></mat-icon></button>
          <button class="me-3 action-buttons" mat-stroked-button color="primary" (click)="resetFilters()"><mat-icon><i
                class="fi-rr-refresh"></i></mat-icon></button>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-12 table-border">
        <table mat-table [dataSource]="records_list" matSort>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Acciones</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="formDialog(element.url_audios)" matTooltip="Escuchar grabación">
                <i class="fi-rr-eye"></i>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="document">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Id agente</th>
            <td mat-cell *matCellDef="let element">{{ element.document }}</td>
          </ng-container>
          <ng-container matColumnDef="agente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre agente</th>
            <td mat-cell *matCellDef="let element">{{ element.agente }}</td>
          </ng-container>
          <ng-container matColumnDef="cedula_cliente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cedula del cliente</th>
            <td mat-cell *matCellDef="let element">{{ element.cedula_cliente }}</td>
          </ng-container>
          <ng-container matColumnDef="nombre_cliente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre del cliente</th>
            <td mat-cell *matCellDef="let element">{{ element.nombre_cliente }}</td>
          </ng-container>
          <ng-container matColumnDef="phone_number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Celular</th>
            <td mat-cell *matCellDef="let element">{{ element.phone_number }}</td>
          </ng-container>
          <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha / Hora</th>
            <td mat-cell *matCellDef="let element">{{ element.created_at }}</td>
          </ng-container>
          <ng-container matColumnDef="minutos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Duración llamada (min)</th>
            <td mat-cell *matCellDef="let element">{{ element.minutos }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td colspan="8">No se encontraron registros</td>
          </tr>
        </table>
        <mat-paginator [length]="length" [pageSize]="pageSize" (page)="paginatorEvent($event)"
          [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
      </div>
    </div>
  </div>
</div>
