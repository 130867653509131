import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const CHAT_SOUL = environment.CHAT_SOUL;

@Injectable({
  providedIn: 'root'
})
export class ChatMeetingsService {

  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse): any {
    return throwError(error);
  }

  /**
   * Obtiene listado de skills segun el grupo seleccionado
   * @author Yeison David Sepulveda Ospina
   * @createdate 20-12-2024
   * @params group_id id del grupo a consultar
   * @returns Observable<any>
   */
  getSkills(group_id: number): Observable<any> {
    return this.http.get(`${CHAT_SOUL}v1/public/groups/${group_id}/groups`)
  }
}



