<div class="row">
  <div class="col-10">
    <h3 class="title" mat-dialog-title>Actualizar el rango de atención</h3>
  </div>
  <div class="col-2 d-flex justify-content-end">
    <i
      class="fi-rr-cross-small"
      style="cursor: pointer; font-size: 20px; color: #353535"
      mat-icon-button
      mat-dialog-close
    ></i>
  </div>
</div>

<mat-dialog-content class="mat-typography">
  <div class="col-12">
    <p>
      ID: {{ rangeAttention.id }}, Rango actual:
      {{ rangeAttention.interval_rooms }} minutos.
    </p>
  </div>
  <mat-divider></mat-divider>

  <form [formGroup]="form" class="mt-4">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Rango</mat-label>
          <mat-select formControlName="interval_rooms" required>
            <mat-option *ngFor="let range of rangeValues" [value]="range.value">
              {{ range.value }} minutos
            </mat-option>
          </mat-select>
          <mat-error *ngIf="getErrorMessage() && intervalRoomsControl.touched">
            {{ getErrorMessage() }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Fondo virtual</mat-label>
          <input
            type="file"
            #fileInput
            (change)="onFileSelected($event)"
            accept=".jpg, .jpeg, .png"
            style="display: none"
          />
          <input
            matInput
            [value]="selectedFileName || 'Ningún archivo seleccionado'"
            readonly
          />
          <button mat-icon-button matSuffix (click)="fileInput.click()">
            <mat-icon>attach_file</mat-icon>
          </button>
        </mat-form-field>
        <mat-hint> <i>{{ getBackgroundName() }}</i> </mat-hint>
      </div>
    </div>

    <div class="row mt-3" *ngIf="imagePreview">
      <div class="col-12 d-flex justify-content-center">
        <img [src]="imagePreview" alt="Preview" style="max-width: 100%; max-height: 200px;">
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions
  align="end"
  class="mt-4 mb-4 actions"
  style="padding-right: 0 !important"
>
  <button
    mat-stroked-button
    color="primary"
    mat-dialog-close
    class="me-2 button-dimensions"
  >
    Cancelar
  </button>
  <button
    mat-raised-button
    color="primary"
    class="button-dimensions"
    type="button"
    (click)="save()"
    [disabled]="form.invalid"
  >
    Actualizar
  </button>
</mat-dialog-actions>
