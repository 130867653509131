import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MeetingsService } from 'src/app/modules/meetings/services/meetings.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { MeetingsAttentionRangeInterface } from '../../interfaces/meetings-attention-range.interface';
import Swal from 'sweetalert2';
import { AttentionRangeInterface } from './attention-range.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-attention-range-form',
  templateUrl: './attention-range-form.component.html',
  styleUrls: ['./attention-range-form.component.sass'],
})
export class AttentionRangeFormComponent implements OnInit {
  form: FormGroup;
  backUrl: string = environment.MEETINGS_URL;

  rangeAttention: MeetingsAttentionRangeInterface = {
    id: 0,
    campaign_id: 0,
    campaign_name: '',
    background_url: '',
    interval_rooms: 0,
  };

  /**
   * Rangos de atención
   * Multiplos de 15, con valores entre 15 y 90 definido por la HU
   */
  rangeValues: any[] = [
    { value: 15 },
    { value: 30 },
    { value: 45 },
    { value: 60 },
    { value: 75 },
    { value: 90 },
  ];

  /**
   * Image setting
   */
  selectedFile: File | null = null;
  selectedFileName: string | null = null;
  imagePreview: string | ArrayBuffer | null = null;
  isImageValid = false;
  allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private fb: FormBuilder,
    private alertService: AlertsService,
    private meetingsService: MeetingsService,
    private dialogRef: MatDialogRef<AttentionRangeFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog_data: dataResponse
  ) {}

  ngOnInit(): void {
    this.rangeAttention = this.dialog_data.data;
    this.initializeForm();
  }

  /**
   * Metodo que inicializa el formulario
   * @author Sergio Cortés
   * @createdate 27-12-2024
   */
  initializeForm() {
    this.form = this.fb.group({
      id: [this.rangeAttention.id || 0, [Validators.required]],
      campaign_id: [
        this.rangeAttention.campaign_id || 0,
        [Validators.required],
      ],
      interval_rooms: [
        this.rangeAttention.interval_rooms || 0,
        [
          Validators.required,
          Validators.min(15),
          Validators.max(90),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      background_url: [null],
    });
  }

  get intervalRoomsControl() {
    return this.form.get('interval_rooms');
  }

  /**
   * Metodo que obtiene el mensaje de error del campo interval_rooms
   * @author Sergio Cortés
   * @createdate 31-12-2024
   */
  getErrorMessage(): string {
    const errors = this.intervalRoomsControl?.errors;

    if (!errors) return '';

    if ('required' in errors) return 'Este campo es requerido.';

    if ('min' in errors)
      return 'El rango de atención mínimo debe ser 15 minutos.';

    if ('max' in errors)
      return 'El rango de atención máximo debe ser 90 minutos.';

    return '';
  }

    /**
   * Metodo para convertir de FormBuilder a FormData para el envío de archivos al Backend
   * @author Sergio Cortés
   * @createdate 07-01-2025
   */
    changeToFormData(body: AttentionRangeInterface): FormData {
      const formData = new FormData();
      formData.append('id', body.id);
      formData.append('interval_rooms', body.interval_rooms);
      formData.append('campaign_id', body.campaign_id);
      if (this.selectedFile) {
        formData.append('background_url', this.selectedFile);
      }
      return formData;
    }

  /**
   * Metodo que guarda el formulario de rango de atención
   * @author Sergio Cortés
   * @createdate 31-12-2024
   */
  save() {
    if (this.form.invalid) {
      this.alertService.alertError(
        '¡Ups!',
        'Por favor, debe validar el formulario.'
      );
      return;
    }

    const body = this.changeToFormData(this.form.value);

    this.meetingsService.updateMeetingRange(body).subscribe({
      next: (resp: string) => {
        this.alertService.alertSuccess('¡Excelente!', resp);
        this.dialogRef.close({ updated: true });
      },
      error: () => {
        this.alertService.alertError(
          '¡Ups!',
          'Ha ocurrido un error al intentar guardar, por favor, intente más tarde.'
        );
        this.dialogRef.close({ updated: false });
      },
      complete: () => this.form.reset(),
    });
  }

    /**
   * Metodo que recibe el archivo cargado en el Input
   * @author Sergio Cortés
   * @createdate 07-01-2025
   */
    onFileSelected(event: Event) {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        // Go to validate files
        this.selectedFile = file;
        this.selectedFileName = file.name;
        this.validateImage(file);
      }
    }

    /**
     * Metodo que Valida la imagen cargada para el fondo
     * @author Sergio Cortés
     * @createdate 07-01-2025
     */
    validateImage(file: File) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const img = new Image();
        img.src = e.target?.result as string;
        img.onload = () => {
          if (!this.allowedTypes.includes(file.type)) {
            Swal.fire({
              title: 'Formato no permitido',
              text: 'El archivo debe ser una imagen en formato JPG, JPEG o PNG.',
              icon: 'error',
            });
            this.selectedFile = null;
            this.selectedFileName = null;
            this.imagePreview = null;
            this.isImageValid = true;
            img.src = null;
            return;
          }

          if (img.width >= 1920 && img.height >= 1080) {
            this.imagePreview = e.target?.result as string;
            this.isImageValid = true;
          } else {
            Swal.fire({
              title: 'Error al cargar la imagen',
              text: 'La imagen debe tener una resolución máxima de 1920x1080 píxeles.',
              icon: 'error',
            });
            this.selectedFile = null;
            this.selectedFileName = null;
            this.imagePreview = null;
            this.isImageValid = false;
            img.src = null;
          }
        };
      };

      // Lee el archivo como Data URL
      reader.readAsDataURL(file);
    }

    /**
     * Metodo que Valida la imagen cargada para el fondo
     * @author Sergio Cortés
     * @createdate 07-01-2025
     */
    getBackgroundName(): string {
      const url = this.rangeAttention.background_url;
      return url ? `Fondo actual: ${url.split('/').pop()}` : '';
    }
}

interface dataResponse {
  data: MeetingsAttentionRangeInterface;
}
