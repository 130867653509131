import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MeetingsService } from '../../services/meetings.service';
import { AlertsService } from 'src/app/shared/alerts/alerts.service';
import { AuthService } from 'src/app/core/services/rest/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RescheduleFormComponent } from './dialogs/reschedule-form/reschedule-form.component';
import * as moment from 'moment';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-meetings-admin',
  templateUrl: './meetings-admin.component.html',
  styleUrls: ['./meetings-admin.component.sass']
})
export class MeetingsAdminComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['actions', 'client_nic', 'scheduled_date', 'group', 'state']
  length: number
  pageSize: number = 5
  pageSizeOptions: number[] = [5, 10, 50, 100]
  page: number = 1
  //Información de la tabla
  data: any[] = []
  //Formulario de filtros
  filters: FormGroup
  //Nombre del rol
  rol_name: string = ""
  //RRHH id del usuario
  private rrhh_id: number = 0
  //Lista de campañas
  campaigns: any[] = []
  //Lista de grupos
  groups: any[] = []
  //Lista de asesores
  advisers: any[] = []
//datasource
dataSource = new MatTableDataSource<any>();
@ViewChild(MatSort) sort: MatSort;

  constructor(private fb: FormBuilder, private vidService: MeetingsService, private alertService: AlertsService, private authService: AuthService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.setRol()
    this.setCampaigns()
    this.setGroups()
    this.setAdvisers()
    this.initializeForm()
    this.getInfo(this.page, this.pageSize, this.rol_name, this.rrhh_id)
  }

  /**
 * Metodo que inicializa el formulario
 * @author Juan Carlos Alonso
 * @createdate 29-11-2024
*/
  initializeForm(): void {
    this.filters = this.fb.group({
      campaign: ['', []],
      group: ['', []],
      adviser: ['', []]
    })
  }
  /**
   * Metodo que filtra la información
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
  */
  filterInfo(): void {
    this.page = 1
    this.getInfo(this.page, this.pageSize, this.rol_name, this.rrhh_id, this.controls.campaign.value, this.controls.group.value, this.controls.adviser.value)
    this.paginator.firstPage()
  }
  /**
   * Metodo que restaura los filtros a su estado inicial
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
  */
  resetFilters(): void {
    this.page = 1
    this.filters.patchValue({ campaign: '', group: '', adviser: '' })
    this.getInfo(this.page, this.pageSize, this.rol_name, this.rrhh_id)
    this.paginator.firstPage()
  }

  get controls() {
    return this.filters.controls
  }

  /** Método que controla la paginación de la tabla
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
  */
  pageEvent(event: any): void {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getInfo(this.page, this.pageSize, this.rol_name, this.rrhh_id, this.controls.campaign.value, this.controls.group.value, this.controls.adviser.value)
  }

  /**
 * Metodo que obtiene la lista de videollamadas con filtros
 * @author Juan Carlos Alonso
 * @createdate 29-11-2024
*/
getInfo(page: number, page_size: number, rol: string, rrhh_id: number, campaign: string = "", group: string = "", adviser: string = "") {
  this.vidService.getVideoCalls(page, page_size, rol, rrhh_id, campaign, group, adviser).subscribe(resp => {
    this.dataSource = new MatTableDataSource(resp.data);
    this.length = resp.total;
    this.pageSize = resp.per_page;
    this.dataSource.sort = this.sort;
  });
}
  /**
   * Metodo para cancelar una videollamada
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
  */
  cancelVideoCall(id: number, scheduled_date: string) {
    const current_schedule = moment(scheduled_date, 'YYYY-MM-DD HH:mm');
    const now = moment();
    const isWithinAnHour = current_schedule.isAfter(now) && current_schedule.diff(now, 'minutes') <= 60;
    const message = isWithinAnHour 
        ? 'Falta menos de una hora, ¿Deseas cancelar la videollamada?' 
        : '¿Deseas cancelar la videollamada?';
    this.alertService.alertWarning('¡Atención!', message).then(confirm => {
        if (!confirm.isConfirmed) return;
        if (isWithinAnHour) {
            this.alertService.alertWarning('¿Estás seguro?', 'No podrás revertir esta acción.').then(confirm => {
                if (!confirm.isConfirmed) return;
                this.executeCancel(id);
            });
        } else {
            this.executeCancel(id);
        }
    });
  }

  /**
   * Metodo para cancelar una videollamada
   * @author Yeison David Sepulveda Ospina
   * @createdate 05-12-2024
  */
  executeCancel(id: number) {
    this.vidService.cancelVideoCall({ id, rrhh_id: this.rrhh_id }).subscribe(
        () => {
            this.alertService.alertSuccess('¡Ok!', 'Se ha cancelado la videollamada con éxito.');
            this.resetFilters();
            this.getInfo(this.page, this.pageSize, this.rol_name, this.rrhh_id);
        },
        () => {
            this.alertService.alertError('¡Atención!', 'Ha ocurrido un error al intentar cancelar la videollamada.');
        }
    );
  }
  
  /**
   * Metodo que asigna un rol y una rrhh_id a la clase
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
  */
  setRol(): void {
    this.rrhh_id = this.authService.getUser().rrhh_id
    this.rol_name = this.authService.getUser().roles.find((rol) => rol.includes('meetings::'))?.split('::')[1]
  }

  openRescheduleForm(id: number) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.width = '60%'
    dialogConfig.height = 'auto'
    dialogConfig.data = {
      id: id,
      rrhh_id: this.rrhh_id
    }

    const dialogRef = this.dialog.open(RescheduleFormComponent, dialogConfig)
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.updated) {
          this.alertService.alertSuccess('!Ok!', 'Se ha reprogramado la videollamada con éxito.')
          this.resetFilters()
        }
      }
    });
  }
  /**
   * Metodo que obtiene las campañas
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
  */
  setCampaigns() {
    this.vidService.getCampaigns().subscribe(resp => {
      this.campaigns = resp
    })
  }
  /**
   * Metodo que obtiene los grupos
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
  */
  setGroups() {
    this.vidService.getGroupListPublic().subscribe(resp => {
      this.groups = resp.groups
    })
  }
  /**
   * Metodo que obtiene los asesores
   * @author Juan Carlos Alonso
   * @createdate 29-11-2024
  */
  setAdvisers() {
    this.vidService.getAdvisersList().subscribe(resp => {
      this.advisers = resp
    })
  }

}
