import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetBackgroundResponse } from '../../meetings/interfaces/get-background.response';

const MEETINGS_URL = environment.MEETINGS_URL;

@Injectable({
  providedIn: 'root'
})
export class VideollamadaService {

constructor(private http: HttpClient) { }

handleError(error: HttpErrorResponse): any {
  return throwError(error);
}

  private MeetResponseSubject = new Subject<any>();
  MeetResponseObservable = this.MeetResponseSubject.asObservable();

  private stateSubject = new Subject<any>();
  stateObservable = this.stateSubject.asObservable();

  /**
   * @author Javier Castañeda
   * @purpose Servicio para actualizar Estado del asesor
   */
   UpdateAdviserState(data: any): Observable<any> {
    return this.http.put<any>(`${MEETINGS_URL}Adviser`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAdviserById(id: number){
    return this.http.get<any>(`${MEETINGS_URL}AdviserBy/${id}`)
    .pipe(
      catchError(this.handleError)
    );
  }

    /**
   * @author Javier Castañeda
   * @purpose Servicio para actualizar Estado de la sala
   */
    UpdateRoomState(data: any): Observable<any> {
      return this.http.put<any>(`${MEETINGS_URL}Room`, data)
        .pipe(
          catchError(this.handleError)
        );
    }

        /**
   * @author Javier Castañeda
   * @purpose Servicio para envio de archivo de una sala
   */
    SendFileRoom(data: any): Observable<any> {
      return this.http.post<any>(`${MEETINGS_URL}RoomFile`, data)
      .pipe(
        catchError(this.handleError)
      );
    }

    /**
   * @author Javier Castañeda
   * @purpose Servicio para obtener los archivos de una sala
   */
    getFilesByRoomId(id: number){
      return this.http.get<any>(`${MEETINGS_URL}GetRoomFiles/${id}`)
      .pipe(
        catchError(this.handleError)
      );
    }

    sendEncolamiento(data: any){
      return this.http.post<any>(`${MEETINGS_URL}Encolamiento`, data)
      .pipe(
        catchError(this.handleError)
      );
    }

    /**
   * @author Javier Castañeda
   * @purpose Servicio para obtener los archivos de una sala
   */
    getRoomById(id: number){
      return this.http.get<any>(`${MEETINGS_URL}RoomBy/${id}`)
        .pipe(
          catchError(this.handleError)
        );
    }

        /**
   * @author Javier Castañeda
   * @purpose Observable para el envio de nic al formulario
   */
  setMeetResponse(value) {
      this.MeetResponseSubject.next(value);
  }

    /**
   * @author Javier Castañeda
   * @purpose Observable para el envio de estado del asesor
   */
  setStateCall(value) {
    this.stateSubject.next(value);
  }


  /**
 * @author Javier Castañeda
 * @purpose Servicio para envio de datos formulario
 */
  sendFormMeetings(data: any): Observable<any> {
    return this.http.post<any>(`${MEETINGS_URL}Room`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
 * @author Javier Castañeda
 * @purpose Servicio para obtener el horario del
 */
  getScheduleByCampaign(id: number){
    return this.http.get<any>(`${MEETINGS_URL}Schedule/getScheduleToday/${id}`);
  }


  /**
   * Obtener listado de grupos disponibles
   * @author Yeison David Sepulveda Ospina
   * @createdate 14-11-2024
   * @returns Observable<any>
   */
  getGroups(): Observable<any> {
    return this.http.get(`${MEETINGS_URL}groups/groupListPublic`)
  }

  /**
   * Obtiene listado de agentes y horarios de disponibilidad en la semana por grupo especifico
   * @author Yeison David Sepulveda Ospina
   * @createdate 26-11-2024
   * @params group_id id del grupo a consultar
   * @params capaign_id El id de la campaña
   * @returns Observable<any>
   */
  getAvailableHours(group_id: number, campaign_id: number): Observable<any> {
    return this.http.get(`${MEETINGS_URL}groups/getAvailableHours/${group_id}/${campaign_id}`)
  }


  /**
   * Servicio para obtener la campaña y la configuracion de la campaña del asesor
   * @author Yeison Sepulveda
   * @created 2024-11-07
  */
  getConfigUser(rrhh_id: any): Observable<any> {
    return this.http.get<any>(`${MEETINGS_URL}Adviser/getConfig/${rrhh_id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  getBackground(campaignId: number): Observable<any> {
    return this.http.get<GetBackgroundResponse>(`${MEETINGS_URL}settings/getBackground/${campaignId}`)
    .pipe(
      catchError(this.handleError)
    );
  }

}
